/* globals VXConfig */
import React          from 'react';
import PropTypes      from 'prop-types';
import {VXPay}        from "../utils/VXPay";
import Translations   from '../utils/Translations';
import Flux           from '../flux/Flux';
import withGuestGuard from './HigherOrderComponents/Guest/withGuestGuard';
import '../../../src/VX/Asset/Less/VX/07-components/bonus-code-info-banner.less';

class BonusCodeInfoBanner extends React.PureComponent {

	constructor() {
		super();

		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		if (Flux.Guest.isLoggedIn()) {
			VXPay.openPaytour();
		} else {
			VXPay.openSignupOrLogin();
		}
	}

	getC2AText() {
		if (Flux.Guest.isLoggedIn()) {
			return Translations.get('BtnChargeNow');
		} else {
			return Translations.get('SignUpNow');
		}
	}

	render() {
		const {text, code, className} = this.props;

		if (!text || !code) {
			return null;
		}

		const codeIndex = text.indexOf(code);

		const t1 = text.substring(0, codeIndex);
		const t2 = text.substring(t1.length + code.length);

		return (
			<div className={"bonus-code-info-banner " + className}>
				<img className={"bonus-code-info-banner-triangle"} src="/assets/icons/bonus_banner_arrow.svg" />

				<div className={"bonus-code-info-banner-text"}>
					{t1}<span className={"bonus-code-info-banner-code"}>{code}</span>{t2}&nbsp;
					<span className={"bonus-code-info-banner-signup"} onClick={this.onClick}>{this.getC2AText()}</span>
				</div>
			</div>
		);
	}
}

BonusCodeInfoBanner.propTypes = {
	text:      PropTypes.string.isRequired,
	code:      PropTypes.string.isRequired,
	isMobile:  PropTypes.bool,
	className: PropTypes.string,
};

BonusCodeInfoBanner.defaultProps = {
	isMobile:  false,
	className: '',
};

export default BonusCodeInfoBanner;

const BonusCodeInfoBannerWithWrapper        = (props) => <div><BonusCodeInfoBanner code={VXConfig.bonusCodeInfo.code || ""}
                                                                                   text={VXConfig.bonusCodeInfo.text || ""} {...props} /></div>;
const BonusCodeInfoBannerWithNotPayingGuard = withGuestGuard(BonusCodeInfoBannerWithWrapper, {
	guardFn: () => VXConfig.bonusCodeInfo && Flux.Guest.isLoggedIn() && !Flux.Guest.isPayingCustomer(),
});
const BonusCodeInfoBannerWithAnonymousGuard = withGuestGuard(BonusCodeInfoBannerWithWrapper, {
	guardFn: () => VXConfig.bonusCodeInfo && !Flux.Guest.isLoggedIn(),
});

export {BonusCodeInfoBannerWithNotPayingGuard, BonusCodeInfoBannerWithAnonymousGuard};
