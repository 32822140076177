import React     from 'react';
import PropTypes from 'prop-types';
import Flux      from '../../../flux/Flux';

function withGuestGuard(WrappedComponent, {guardFn}) {
	class withGuestGuard extends React.Component {

		constructor(props) {
			super(props);

			this.state = {
				isValid: guardFn(),
			};

			this.onGuestChange = this.onGuestChange.bind(this);
		}

		componentDidMount() {
			Flux.Guest.addGuestChangeListener(this.onGuestChange);
		}

		componentWillUnmount() {
			Flux.Guest.removeGuestChangeListener(this.onGuestChange);
		}

		onGuestChange() {
			this.setState({
				isValid: guardFn(),
			});
		}

		render() {
			return this.state.isValid ? <WrappedComponent {...this.props} /> : null;
		}
	}

	return withGuestGuard;
}

withGuestGuard.propTypes = {
	guardFn: PropTypes.func.isRequired,
};

export default withGuestGuard;
